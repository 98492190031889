<template>
  <v-app>
    <v-app-bar app color="#E84855">
      <h3 style="color: white;">Greg Shaw</h3>
      <v-spacer />
      <div v-if="width < 700">
        <v-btn x-small text dark to="/">Home</v-btn>
        <v-btn x-small text dark>Bio</v-btn>
        <v-btn x-small text dark>Donate</v-btn>
        <v-btn x-small text dark :href="`https://www.sos.ms.gov/voter-id/register`" target="_blank">Register to Vote
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small text dark v-bind="attrs" v-on="on">
              Contact
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-col>
                <v-row>
                  <v-icon>mdi-phone</v-icon>
                </v-row>
                <v-row>
                  <body-1>(985) 956-9514</body-1>
                </v-row>
              </v-col>
            </v-list-item>
            <v-list-item>
              <v-col>
                <v-row>
                  <v-icon>mdi-email</v-icon>
                </v-row>
                <v-row>
                  <body-1>gregshawforjcjudgeprc@gmail.com</body-1>
                </v-row>
              </v-col>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-else>
        <v-btn text dark to="/">Home</v-btn>
        <v-btn text dark>Bio</v-btn>
        <v-btn text dark>Donate</v-btn>
        <v-btn text dark :href="`https://www.sos.ms.gov/voter-id/register`" target="_blank">Register to Vote</v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text dark v-bind="attrs" v-on="on">
              Contact
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-icon>mdi-phone</v-icon>
              <h3>&emsp;(985) 956-9514</h3>
            </v-list-item>
            <v-list-item>
              <v-icon>mdi-email</v-icon>
              <h3>&emsp;gregshawforjcjudgeprc@gmail.com</h3>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return { width: window.innerWidth, height: window.innerHeight, drawer: false, group: '', };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      console.log(this.width, this.height);
    },
  },
};
</script>
