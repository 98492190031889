<template>
    <v-container>
        <v-row>
            <v-col style="width: 50vw">
                <v-img :src="'charlie.jpg'" width="30vw"></v-img>
            </v-col>
            <v-col style="width: 50vw" class="d-flex align-center justify-center">
                <h1 class="d-flex text-center" width="30vw">Greg Shaw for Pearl River Count Justice Court Judge North</h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
    }),
};
</script>